<template>
  <div class="editor">
    <div class="editor_title">
      <div class="title_left" @click="rename">{{ textName }}.docx</div>
      <div class="activeNum">字符数：{{ sum.length }}</div>
    </div>

    <div class="editor_box">
      <!-- 工具栏 -->
      <div class="Toolbar_box">
        <div class="save_box" @click="handleSave">
          <img :src="require('assets/images/save.png')" alt="" />
        </div>
        <Toolbar
          class="toolbar"
          :editor="editor"
          :defaultConfig="toolbarConfig"
        />
      </div>
      <!-- 编辑器 -->
      <div class="box">
        <div class="jia_box">
          <div class="box_jia"></div>
          <div class="box_jia"></div>
        </div>
        <Editor
          class="Editor_box"
          style="overflow-y: hidden"
          :defaultConfig="editorConfig"
          v-model="html"
          @onChange="onChange"
          @onCreated="onCreated"
          @onBlur="onBlur"
        />
      </div>
    </div>

    <!-- 重命名 -->
    <el-dialog
      title="重命名"
      :visible.sync="renameDialog"
      class="rename"
      :modal="false"
      top="0"
      :before-close="handleCloseRename"
    >
      <div class="content">
        <div class="content-label">文件名</div>
        <input type="text" v-model="fileNameInput" class="content-input" />
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="footer-btn sure" @click="sureRename">确定</div>
        <div class="footer-btn" @click="handleCloseRename">取消</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
  name: "editor",
  components: { Editor, Toolbar },
  props: {
    returnJoinWord: {},
  },
  data() {
    return {
      editor: null,
      // 文本
      html: "",
      // 字符数
      sum: "",
      // 富文本的标题名字
      textName: "未命名",
      // 重命名弹窗
      renameDialog: false,
      // 重命名输入框数据
      fileNameInput: "",

      toolbarConfig: {
        toolbarKeys: [
          /* 显示哪些菜单，如何排序、分组 */
          "undo",
          "redo",
          "headerSelect",
          "bold",
          "underline",
          "italic",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>',
            key: "group-more-style",
            menuKeys: ["through", "sup", "sub", "clearStyle"],
            title: "更多",
          },
          "color",
          "fontSize",
          "fontFamily",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            key: "group-justify",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
            title: "对齐",
          },
          "divider",
        ],
        excludeKeys: [
          /* 排除菜单组，写菜单组 key 的值即可 */
        ],
      },
      editorConfig: {
       // placeholder: "请输入溯源内容，溯源内容不会被收录进入数据库...",
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
    };
  },
  watch: {
    html: {
      handler: function (cur) {
        if (cur && cur != "") {
          this.sum = cur.replace(/<[^>]+>/g, "");
          this.sum = this.sum.replace(/&nbsp;/g, " ");
        } else {
          this.sum = "";
        }
      },
    },
    returnJoinWord: {
      handler: function (val) {
        if (val && val != "") {
          this.html = val;
        } else {
          this.html = "";
        }
      },
    },
  },
  methods: {
    handleSave() {
      console.log("保存");
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      // this.$emit("htmlFont", {
      //   font: this.html,
      //   centerBoxTitle: this.textName,
      // });
      //   const toolbar = DomEditor.getToolbar(editor);
      //   const menu = editor.getAllMenuKeys();
      //   const bar = toolbar.getConfig().toolbarKeys;
      //   console.log(menu);
      //   console.log(bar);
      // onChange 时获取编辑器最新内容
    },
    onBlur() {},

    insertTextHandler() {
      const editor = this.editor;
      if (editor == null) return;
      editor.insertText(" hello ");
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;
    },

    // 打开重命名
    rename() {
      this.renameDialog = true;
      this.fileNameInput = this.textName;
    },

    //  重命名弹窗关闭触发
    handleCloseRename() {
      this.renameDialog = false;
      this.fileNameInput = "";
    },

    //确定重命名
    sureRename() {
      if (/ /.test(this.fileNameInput)) {
        return this.$message({
          type: "error",
          message: "不能有空格字符",
        });
      }
      this.textName = this.fileNameInput;
      this.renameDialog = false;
      this.$emit("htmlFont", {
        font: this.html,
        centerBoxTitle: this.textName,
      });
    },
  },
  mounted() {},

  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.editor {
  margin: 0 auto;
  width: 773px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .editor_title {
    display: flex;
    height: 30px;
    background: #f7f7f7;
    align-items: center;
    justify-content: space-between;
    .title_left {
      min-width: 170px;
      max-width: 200px;
      height: 30px;
      overflow: hidden;
      background: #ffffff;
      text-indent: 2em;
      line-height: 30px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }

    .activeNum {
      font-size: $font-size-medium;
      font-family: PingFang SC;
      font-weight: 500;
      color: $color-text-gray;
      margin-right: 13px;
    }
  }

  .editor_box {
    flex: 1;

    display: flex;
    flex-direction: column;

    .Toolbar_box {
      position: relative;
      .save_box {
        position: absolute;
        left: 80px;
        top: 3px;
        z-index: 1;
        width: 30px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: #f1f1f1;
        }
        img {
          display: block;
          width: 20px;
          height: 20px;
        }
      }
      .toolbar {
        ::v-deep .w-e-toolbar {
          .w-e-bar-item {
            &:nth-child(2) {
              margin-right: 30px;
            }
          }
        }
      }
    }

    // 编辑器
    .box {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #fff;
      width: 95%;
      margin: 20px auto;

      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.1);
      .jia_box {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 10px;
        .box_jia {
          width: 35px;
          height: 35px;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;

          &:nth-child(2) {
            width: 35px;
            height: 35px;
            border-right: 0px solid #000;
            border-bottom: 0px solid #000;
            border-left: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
          }
        }
      }

      .Editor_box {
        padding: 0 45px;
        height: 745px;

        ::v-deep .w-e-text-container {
          ::-webkit-scrollbar {
            // height: 100%;
            display: none;
          }
        }
      }
    }
  }

  // 重命名弹窗样式
  .rename {
    ::v-deep .el-dialog {
      width: 428px;
      height: 253px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .el-dialog__header {
        padding: 0;
        height: 42px;
        background: #f4f6f7;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 14px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 50px 30px 0;
        .content {
          display: flex;
          height: 50px;
          align-items: center;
          &-label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 12px;
          }
          &-input {
            width: 290px;
            height: 50px;
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 5px;
            padding-left: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
        }
      }
      .el-dialog__footer {
        display: flex;
        justify-content: flex-end;
        padding: 50px 30px 0 0;
        .dialog-footer {
          display: flex;
          .footer-btn {
            width: 71px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }
          .sure {
            background: #4c8cff;
            color: #ffffff;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
</style>
