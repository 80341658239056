var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"traceTheSource-historical-tracing"},[_c('div',{staticClass:"traceTheSource-historical-tracing-header"},[_c('div',{staticClass:"back",on:{"click":_vm.goBack}},[_c('i',{staticClass:"icon"}),_vm._v("文本溯源")]),_c('span',{staticClass:"title"},[_vm._v("历史溯源报告")]),_c('span',{staticClass:"refresh",on:{"click":_vm.refresh}},[_c('img',{attrs:{"src":require('assets/images/refresh-gray.png'),"alt":""}})])]),_c('div',{staticClass:"traceTheSource-historical-tracing-reportform"},[_c('div',{staticClass:"traceTheSource-historical-tracing-reportform-search"},[_c('div',{staticClass:"query-filename"},[_c('el-input',{attrs:{"placeholder":"请输入文件名搜索","suffix-icon":"el-icon-search"},on:{"change":_vm.searchFileName},model:{value:(_vm.queryFileName),callback:function ($$v) {_vm.queryFileName=$$v},expression:"queryFileName"}})],1),_c('div',{staticClass:"query-date"},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"请选择日期搜索","value-format":"yyyy-MM-dd"},on:{"change":_vm.searchDate},model:{value:(_vm.queryDate),callback:function ($$v) {_vm.queryDate=$$v},expression:"queryDate"}})],1)]),_c('div',{staticClass:"traceTheSource-historical-tracing-reportform-table"},[_c('el-table',{staticStyle:{"margin":"0 auto"},attrs:{"data":_vm.traceReportList}},[_c('el-table-column',{attrs:{"prop":"","label":"序号","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.row.status;
var index = ref.$index;
return [_c('div',{class:[
                'states',
                status === 0
                  ? 'orange'
                  : status === 1
                  ? 'blue'
                  : status === 2
                  ? 'green'
                  : 'gray'
              ]},[_vm._v(" "+_vm._s(status === 0 ? "未溯源" : status === 1 ? "溯源中" : status === 2 ? "完成" : "失败")+" ")]),_c('div',[_vm._v(_vm._s(index + 1))])]}}])}),_c('el-table-column',{attrs:{"label":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fileName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"类型","sortable":true,"sort-method":_vm.sortType,"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.reportType == 1 ? "文档" : scope.row.reportType == 2 ? "图片" : scope.row.reportType == 3 ? "视频" : scope.row.reportType == 4 ? "音频" : scope.row.reportType == 0 ? "富文本" : "-")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"数据大小","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fs)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"价格","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.price)+"字符 ")]}}])}),_c('el-table-column',{attrs:{"prop":"timeSpan","label":"溯源时间","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.createTime)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"溯源评价","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ref_row = ref.row;
            var reportType = ref_row.reportType;
            var level = ref_row.level;
            var evaluation = ref_row.evaluation;
            var status = ref_row.status;
            var status_remark = ref_row.status_remark;
return [_c('div',{staticClass:"evaluate-wrap"},[(status === 0 && !evaluation)?_c('div',{staticClass:"nothing"},[_vm._v(" 未溯源 ")]):_vm._e(),(status === 3)?_c('div',{staticClass:"failed"},[_vm._v(" "+_vm._s(status_remark)+" ")]):_vm._e(),((reportType == 1 || reportType == 0) && evaluation)?_c('div',{staticClass:"original"},[_c('i',{staticClass:"levelIcon",class:level == 'A+'
                      ? 'Aadd'
                      : level == 'A'
                      ? 'A'
                      : level == 'B+'
                      ? 'Badd'
                      : level == 'B'
                      ? 'B'
                      : 'C'}),_c('span',{staticClass:"txt"},[_vm._v("严格原创率"+_vm._s(evaluation)+"%")])]):_vm._e(),(reportType > 1 && evaluation)?_c('div',{staticClass:"homology"},[(evaluation == '有同源')?_c('img',{attrs:{"src":require('assets/images/homology-y.png'),"alt":""}}):_c('img',{attrs:{"src":require('assets/images/homology-n.png'),"alt":""}}),_c('span',{class:[
                    'txt',
                    evaluation == '有同源'
                      ? 'is-homology'
                      : evaluation == '无同源'
                      ? 'no-homology'
                      : ''
                  ]},[_vm._v(" "+_vm._s(evaluation)+" ")])]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"label":"在线授权","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:[
                'authorize',
                scope.row.originalIds && scope.row.originalIds != ''
                  ? 'is-blue'
                  : 'is-gray'
              ],on:{"click":function($event){return _vm.showAuthorize(scope.row.originalIds, scope.row.id)}}},[_vm._v(" "+_vm._s(scope.row.originalIds && scope.row.originalIds != "" ? "可在线授权" : "无在线授权")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"溯源报告","width":"144"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ref_row = ref.row;
            var pdf = ref_row.reportUrl;
            var id = ref_row.id;
            var fileName = ref_row.fileName;
            var url = ref_row.url;
            var status = ref_row.status;
            var i = ref.$index;
return [_c('div',{staticClass:"operate"},[(_vm.completeStatus(status))?_c('span',{staticClass:"view",attrs:{"title":"查看"},on:{"click":function($event){return _vm.openPdf(pdf)}}},[_c('i')]):_vm._e(),(_vm.completeStatus(status))?_c('span',{staticClass:"down",attrs:{"title":"下载"},on:{"click":function($event){$event.stopPropagation();return _vm.selectMenu(i)}}},[_c('i'),_c('div',{class:['submenu', _vm.subIndex === i ? 'submenu-active' : ''],on:{"click":function($event){return _vm.selectMenuItem($event, fileName)}}},[_c('span',{attrs:{"data-subitem":pdf}},[_vm._v("溯源报告")]),_c('span',{attrs:{"data-subitem":url}},[_vm._v("源文件")])])]):_vm._e(),(_vm.completeStatus(status))?_c('span',{staticClass:"share",attrs:{"title":"分享"},on:{"click":function($event){return _vm.QrCodeShow(pdf, fileName)}}},[_c('i')]):_vm._e(),_c('span',{staticClass:"delete",attrs:{"title":"删除"},on:{"click":function($event){return _vm.delHandler(id)}}},[_c('i')])])]}}])})],1)],1)]),_c('div',{staticClass:"traceTheSource-historical-tracing-footer"},[_c('el-pagination',{attrs:{"background":"","layout":"prev,pager,next","prev-text":"上一页","next-text":"下一页","total":_vm.traceReportTotal,"hide-on-single-page":"","page-size":_vm.pageSize,"current-page":_vm.pageNum},on:{"current-change":_vm.currentChange}})],1),_c('el-dialog',{staticClass:"qrcode",attrs:{"title":"二维码","close-on-click-modal":false,"visible":_vm.QrCodeVisible},on:{"update:visible":function($event){_vm.QrCodeVisible=$event},"close":_vm.handleCloseQrcode}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"notes"},[_vm._v("分享二维码")]),_c('div',{staticClass:"imgBox"},[_c('div',{ref:"qrcode_box"})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }