<template>
  <div class="trace-progress">
    <div class="trace-progress-inner" ref="outer">
      <div class="range" ref="inner"></div>
    </div>
    <div class="trace-progress-text">{{percent}}%</div>
  </div>
</template>

<script>
export default {
  name : "TraceProgress",
  props : {
    percentage : {
      type : Number,
      default : 0
    }
  },
  watch : {
    percentage(val){
      if(val == 0){
        this.$refs["inner"].style.width = "0px";
        setTimeout(()=>{
          this.load();
        },300)
      }
      this.percent = val;
    }
  },
  data(){
    return {
      percent : this.percentage
    }
  },
  methods : {
    load(){
      const outer = this.$refs["outer"];
      const inner = this.$refs["inner"];
      const outerWidth = outer.clientWidth;
      let dis = 0;
      const b = outerWidth / 100;
      let timer1,timer2,timer3;
      timer1 = setInterval(()=>{
        if(dis >= 50){
          clearInterval(timer1);
          timer2 = setInterval(()=>{
            dis += Math.floor(Math.random() * 3 + 1);
            this.percent = dis;
            inner.style.width = dis * b + "px";
            if(dis >= 80){
              clearInterval(timer2);
            }
          },200);
        }
        dis += 3;
        this.percent = dis;
        inner.style.width = dis * b + "px";
      },100);
      timer3 = setInterval(()=>{
        if(this.percentage == 100){
          clearInterval(timer1);
          clearInterval(timer2);
          clearInterval(timer3);
          inner.style.width = 100 * b + "px";
        }
      },50);
    }
  },
  mounted(){
    this.$refs["inner"].style.width = 0;
    this.load();
  }
}
</script>

<style lang="scss" scoped>
.trace-progress{
  display:flex;
  width:100%;
  box-sizing:border-box;
  &-inner{
    width:155px;
    background-color:#f6f6f6;
    border-radius:100px;
    .range{
      width:0;
      height:15px;
      border-radius:100px;
      background-color:#4587ff;
      transition:width .3s;
    }
  }
  &-text{
    font-size:14px;
    color: #606266;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}
</style>